import { FC } from 'react';

import Cookies from 'js-cookie';
import { SessionProvider } from 'next-auth/react';

import { ChatProvider } from '@apps/chat/shared/multilabel/src/ChatProvider';
import { Locale } from '@common/application';
import type { AppProps } from '@dxp-next';
import { NEXT_LOCALE_COOKIE } from '@dxp-next/constants';
import { SparkyProvider } from '@sparky/providers';

import { seamlyConfig } from '../config';

const MainApp: FC<AppProps> = ({ Component, pageProps: { session, ...pageProps }, router }) => {
  const locale = Cookies.get(NEXT_LOCALE_COOKIE) ?? 'nl';
  const path = router.asPath;
  const segment = path.split('/')[1];
  let sparkyLocale: Locale = 'nl-BE';

  if (segment === 'business' || segment === 'b2b' || segment === 'entreprises') {
    seamlyConfig.defaultNamespace = 'b2b-be';
  }

  if (locale === 'fr') {
    seamlyConfig.defaultLocale = 'fr';
    seamlyConfig.userLocale = 'fr';
    sparkyLocale = 'fr-BE';
  }

  // There is no German chatbot so we will use the French one and automatically translate it to German
  if (locale === 'de') {
    seamlyConfig.defaultLocale = 'fr';
    seamlyConfig.userLocale = 'de-informal';
    sparkyLocale = 'fr-BE';
  }

  return (
    <SessionProvider session={session} basePath={`${router.basePath}/api/auth`}>
      <SparkyProvider locale={sparkyLocale}>
        <ChatProvider config={seamlyConfig}>
          <Component {...pageProps} />
        </ChatProvider>
      </SparkyProvider>
    </SessionProvider>
  );
};

export default MainApp;
